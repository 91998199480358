.player {
  width: 100%;

  .section {
    width: 100%;
    max-width: 610px;
    margin: 0 auto;
    display: flex;

    &.menu {
      gap: 8px;
      flex-direction: row;

      >.item {
        flex-grow: 1;
      }
    }

    &.list {
      gap: 8px;
      flex-direction: column;
      margin-top: 24px;

      >.item {
        flex-grow: 1;
        position: relative;

        >.btn {
          width: 100%;
        }

        >.actions {
          text-align: center;
          display: flex;

          >.link {
            margin: 0 5px;
            flex: 1 1;
          }
        }

        .content {
          word-wrap: break-word;
        }
      }
    }

    &.play {
      gap: 8px;
      flex-direction: row;
      margin-top: 24px;
      flex-wrap: wrap;

      >.item {
        flex-grow: 1;
      }

      >.item:last-of-type {
        flex-basis: 100%;
      }
    }

    &.edit {
      margin-top: 24px;
      flex-direction: column;
      align-items: center;

      >input {
        width: 100%;
        padding: 8px;
        box-sizing: border-box;
      }

      >.text {
        margin: 8px;
      }
    }

    .btn {
      display: inline-block;
      padding: 12px 16px;
      box-sizing: border-box;
      border: 1px solid rgb(138, 136, 134);
      cursor: pointer;

      &.selected {
        background-color: #0078d4;
        color: white;
      }
    }

    .link {
      color: rgb(0, 120, 212);
      cursor: pointer;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  >.img-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.75);

    >img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: scale-down;

      &.portrait {
        transform: rotate(90deg);
        transform-origin: left top;
      }
    }

    >.close-btn {
      position: absolute;
      cursor: pointer;

      top: 4px;
      right: 4px;

      font-size: 40px;
      width: 62px;
      height: 62px;
      font-weight: 600;
      border: none;
      z-index: 1000;
    }
  }
}